.modal-title {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.toggle-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 30px;
  width: 118px;
  margin-bottom: 0px;
}

.toggle-label .switch-wrapper {
  border-radius: 20px;
  cursor: pointer;
  height: 20px;
  float: left;
  overflow: hidden;
  position: relative;
  width: 40px;
}

.toggle-label .switch-wrapper > input[type='checkbox'] {
  display: none;
}

.toggle-label .switch-wrapper > input[type='checkbox']:checked + .switch {
  -webkit-transform: translateX(68%) translateX(-35px);
  transform: translateX(68%) translateX(-35px);
}

.toggle-label .switch-wrapper .switch {
  border-radius: 20px;
  color: #fff;
  display: inline-block;
  height: 100%;
  left: -100%;
  position: relative;
  -webkit-transition: 100ms linear;
  transition: 100ms linear;
  width: 200%;
}

.toggle-label .switch-wrapper .switch > .switch-handle {
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 15px;
  width: 15px;
  position: relative;
  left: 50%;
  margin-left: 3px;
  top: -5px;
  z-index: 3;
}

.toggle-label .switch-wrapper .gray-style {
  background-color: #67676767;
}

.toggle-label .switch-label {
  float: left;
  margin-bottom: 0px;
  margin-left: 10px;
  font-size: 0.75rem;
}
/*# sourceMappingURL=Toggle.css.map */
