.form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.form .eui {
  width: 25%;
}

.form .network-key {
  width: 25%;
}

.form .short-address {
  width: 25%;
}
/*# sourceMappingURL=AddMiotyComponent.css.map */
