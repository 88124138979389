.modal-location {
  min-width: 700px;
  top: 100px;
  max-height: 420px;
}

.modal-location .modal-display {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  overflow-y: scroll;
  max-height: 420px;
}

.dropright .dropdown-toggle::after {
  margin-top: 13px;
}

.dropdown-toggle {
  text-align: left;
  min-width: 135px;
  margin-bottom: 5px;
}

.device-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.edit-icon {
  fill: 'black';
  height: 24;
  width: 24;
  margin-left: '20px';
}

.drop-down-width {
  min-width: 135px;
}

.button-wrap {
  white-space: 'nowrap';
}

.icon-margin {
  margin-left: 5px;
  margin-right: 5px;
}

.device-details {
  display: 'flex';
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: 'row' !important;
  flex-direction: 'row' !important;
}

.device-id {
  width: '175px';
}

.mioty-add-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.mioty-add-header .mioty-header-eui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  min-width: 25%;
  padding-left: 10px;
}

.mioty-add-header .mioty-header-network {
  padding-left: 5px;
  min-width: 25%;
}

.mioty-add-header .mioty-header-short-address {
  min-width: 25%;
}

.mioty-add-header .mioty-header-blueprint {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mioty-add-header .mioty-header-blueprint .icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  padding-left: 20px;
}
/*# sourceMappingURL=createDevice.css.map */
