.meshrouter-background {
  background-color: #03a9f4;
}

.info-text-color {
  color: #03a9f4;
}

.traksense360-background {
  background-color: #11bf1d;
}

.traksense360-color {
  color: #11bf1d;
}

.icon-style {
  font-size: 1.6em;
  margin: 0 auto;
  display: block;
}

input::-webkit-input-placeholder {
  font-size: 0.8rem;
}

input:-ms-input-placeholder {
  font-size: 0.8rem;
}

input::-ms-input-placeholder {
  font-size: 0.8rem;
}

input::placeholder {
  font-size: 0.8rem;
}

.pointer {
  cursor: pointer;
}

.align {
  text-align: center;
}

.batch-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.batch-buttons-input {
  max-width: 300px;
  padding: 1rem;
}

.chameleon-device-div {
  position: relative;
}

.chameleon-device-checkbox {
  margin-bottom: 16px;
}

.app-page-title-div {
  margin-bottom: 0px;
  padding: 0.5rem;
}

#page-title-heading-div {
  font-size: 0.9rem;
}

#page-title-icon-div {
  font-size: 2rem;
  height: 3rem;
  width: 3rem;
}

.alert {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.alert-div {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  justify-self: flex-start;
}

.card-body-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-menu-div {
  height: 130px;
}
/*# sourceMappingURL=devices.css.map */
