.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  z-index: 99;
}

.popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 0.75rem;
  width: 450px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.2px solid #dee2e6;
  position: absolute;
  top: 20%;
  left: 30%;
  z-index: 100;
  background-color: #f8f9fa;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  opacity: 1;
}

.popup .header {
  font-size: 1.25rem;
  font-weight: 400;
  padding: 10px;
  border-bottom: 0.5px solid #dee2e6;
  background-color: #f8f9fa;
  line-height: 1.5;
  color: #495057;
  border-top-right-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
  padding-left: 20px;
}

.popup .content {
  font-size: 0.88rem;
  color: #777777;
  padding: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  border-bottom: 0.5px solid #dee2e6;
}

.popup .footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-color: #f8f9fa;
  padding: 10px;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
/*# sourceMappingURL=Popup.css.map */
