.dropright .dropdown-toggle::after {
  margin-top: 13px;
}

.dropdown-toggle {
  text-align: left;
  min-width: 135px;
  margin-bottom: 5px;
}

.device-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.edit-icon {
  fill: 'black';
  height: 24;
  width: 24;
  margin-left: '20px';
}

.drop-down-width {
  min-width: 154px;
}

.drop-down-width-box {
  min-width: 135px;
}

.button-wrap {
  white-space: 'nowrap';
}

.icon-margin {
  margin-left: 5px;
  margin-right: 5px;
}

.device-details {
  display: 'flex';
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: 'row' !important;
  flex-direction: 'row' !important;
}

.device-id {
  width: '175px';
}
/*# sourceMappingURL=ChameleonSelector.css.map */
