// General

a,
button,
.btn {
  outline: none !important;
}

.app-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
}

.logo-wrapper {
  width: 85px;
  height: 85px;
}
.logo-home {
  transform: scale(0.7);
  filter: invert(100%);
}

.logo-wrapper img {
  width: 100%;
  height: 100%;
}
